import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Image,
  useDisclosure,
  useColorModeValue,
  Stack,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { MenuItems } from "./MenuItems";
import { useRouter } from 'next/router';

const NavLink = (props) => {
  return (
    <Link
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.200", "gray.700"),
      }}
      href={props.link}
    >
      {props.name}
    </Link>
  );
};

const TopNavMenuList = (props) => {
  return (
    <MenuList bgColor={props.bgColor} borderWidth="0" boxShadow="none">
      {props.children}
    </MenuList>
  );
};

const TopNavMenuItem = (props) => {
  return (
    <MenuItem
      onClick={() => {
        if (props.target === "current") {
          location.href = props.link;
        } else {
          window.open(props.link)
        }
      }}
      _focus={{ bg: "transparent" }}
      _hover={{ bg: "transparent" }}
    >
      {props.name}
    </MenuItem>
  );
};

const TopNavMenuButton = (props) => {
  return (
    <MenuButton
      as={Button}
      rightIcon={<ChevronDownIcon />}
      fontFamily="Heading Font Name"
      height="40px"
      bg="transparent"
      borderRadius="20"
      color={props.dark ? "#000" : "#fff"}
      _hover={props.dark ? { bg: "#000", color: "#fff" } : { bg: "white", color: "black" }}
    >
      {props.label}
    </MenuButton>
  );
};

export default function ITNavBar(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const router = useRouter();

  const routeToPage = (evt, url) => {
    if (url) {
      router.push(url);
    } else {
      router.push("/");
    }
  }

  return (
    <Box px={4} color="white" background={isOpen ? "#000" : "inherit"}>
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <IconButton
          fontSize="32px"
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
          backgroundColor="transparent"
          borderWidth="0"
        />
        <HStack>
          <Image
            src="/images/fk-logo-white.png"
            w="48px"
            marginLeft={110}
            cursor="pointer"
            title="Founders Key Home"
            alt="Founders Key Home"
            onClick={routeToPage}
          />
        </HStack>

        <Flex
          alignItems={"center"}
          display={{ base: "none", md: "flex" }}
          paddingRight="110px"
        >
          <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
            <Menu>
              <TopNavMenuButton label="PROJECTS" dark={props.dark} />
              <TopNavMenuList
                bgColor="transparent"
                borderWidth="0"
                boxShadow="none"
              >
                <TopNavMenuItem
                  link="https://projectkyzen.io"
                  name="Project Kyzen"
                />
                <TopNavMenuItem link="https://merrymodz.io" name="Merry Modz" />
              </TopNavMenuList>
            </Menu>

            <Menu>
              <TopNavMenuButton label="STORE" dark={props.dark} />
              <TopNavMenuList
                bgColor="transparent"
                borderWidth="0"
                boxShadow="none"
              >
                <TopNavMenuItem
                  link="https://opensea.io/collection/impact-theory-founders-key"
                  name="Impact Theory Founders Key"
                />
                <TopNavMenuItem
                  link="https://opensea.io/collection/merry-modz"
                  name="Merry Modz"
                />
                <TopNavMenuItem
                  link="https://opensea.io/collection/relentlesslifemap"
                  name="Relentless Lifemap"
                />
                <TopNavMenuItem
                  link="https://tokentrove.com/collection/ProjectKyzenPreGenesis"
                  name="Pre-Genesis"
                />
              </TopNavMenuList>
            </Menu>
            <Link
              px={2}
              py={1}
              rounded={"md"}
              fontSize="16px"
              fontFamily="Heading Font Name"
              borderRadius="20"
              isExternal
              color={props.dark ? "#000" : "#fff"}
              _hover={props.dark ? { bg: "#000", color: "#fff", textDecoration: "none" } : { bg: "white", color: "black", textDecoration: "none" }}
              onClick={() => (window.location.href = "/perks")}
            >
              PERKS
            </Link>
            <Menu>
              <TopNavMenuButton label="WIKI" dark={props.dark} />
              <TopNavMenuList
                bgColor="transparent"
                borderWidth="0"
                boxShadow="none"
              >
                <TopNavMenuItem
                  link="https://wiki.projectkyzen.io/"
                  name="Project Kyzen"
                />
                <TopNavMenuItem
                  link="https://wiki.founderskey.io/"
                  name="Founders Key"
                />
              </TopNavMenuList>
            </Menu>
          </HStack>
        </Flex>
      </Flex>

      {/* mobile */}
      {isOpen ? (
        <Box pb={4} display={{ md: "none", bgColor: "#000" }}>
          <Stack as={"nav"} spacing={4} w="200px">
            {MenuItems.map(el => (
              <Menu key={el.name}>
                <TopNavMenuButton label={el.name} />
                <TopNavMenuList bgColor="#000" borderWidth="0" boxShadow="none">
                  {el.children.map(link => (
                    <TopNavMenuItem
                      key={link.name}
                      link={link.link}
                      name={link.name}
                    />
                  ))}
                </TopNavMenuList>
              </Menu>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}
