import React from 'react';
import { Text, Link, textDecoration } from "@chakra-ui/react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

// Create a bespoke renderOptions object to target BLOCKS.EMBEDDED_ENTRY (linked entries e.g. videoEmbed
// and BLOCKS.EMBEDDED_ASSET (linked assets e.g. images)

const options = {
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
            <Text
                m="10px 0"
                w="100%"
                fontSize="1.2em"
                lineHeight="28px"
                p="0 0"
            >{children}</Text>
        ),
        [INLINES.HYPERLINK]: (node, children) => {
            return <Link href={node.data.uri} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline', color: 'blue'}}>{node.content[0].value}</Link>;
        }
    },
};

export default function BannerTextRenderer(data) {
    const retVal = documentToReactComponents(data, options);
    return retVal;
    // return <>{retVal}</>;
}