import styles from "../styles/Home.module.css";
import { SocialIcon } from "react-social-icons";
import { Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export default function Footer() {
  const [iconSize, setIconSize] = useState(50);

  useEffect(() => {
    function handleResize() {
      setSocialIcons();
    }
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    setSocialIcons();
  }, []);

  const setSocialIcons = () => {
    if (window.innerWidth < 600) {
      setIconSize(20);
    } else if (window.innerWidth < 700) {
      setIconSize(35);
    } else {
      setIconSize(50);
    }
  };

  const ITSocialIcon = (props) => {
    return (
      <SocialIcon
        url={props.url}
        bgColor="black"
        style={{ height: iconSize, width: iconSize }}
      />
    );
  };

  return (
    <footer className={styles.footer}>
      <div>
        <Stack direction={["row"]} spacing={4} align="center">
          <div>
            <a href="termsofuse">Terms</a>
          </div>
          <div>
            <a href="privacypolicy">Privacy</a>
          </div>
          <div>
            <a href="https://impacttheory.com/" target="_blank" rel="noopener noreferrer">About</a>
          </div>
          <div>
            <a href="https://www.sec.gov/news/press-release/2023-163" target="_blank" rel="noopener noreferrer">SEC Order</a>
          </div>
          <div>
            <a href="https://www.sec.gov/news/statement/peirce-uyeda-statement-nft-082823" target="_blank" rel="noopener noreferrer">SEC Dissent</a>
          </div>
        </Stack>
      </div>
      <div>
        <Stack direction={["row"]} spacing={4} align="center">
          <ITSocialIcon url="https://discord.gg/VhJE7m32WF" />
          <ITSocialIcon url="https://twitter.com/impact_theory" />
          <ITSocialIcon url="https://www.facebook.com/tombilyeu/?ref=br_rs" />
          <ITSocialIcon url="https://www.instagram.com/tombilyeu/" />
          <ITSocialIcon url="https://www.youtube.com/channel/UCnYMOamNKLGVlJgRUbamveA" />

          <span style={{ fontSize: ".75rem" }}>&copy; Impact Theory 2022</span>
        </Stack>
      </div>
    </footer>
  );
}
