export const MenuItems = [
  {
    name: "Projects",
    children: [
      { name: "Project Kyzen", link: "https://projectkyzen.io" },
      { name: "Merry Modz", link: "https://merrymodz.io/" },
    ],
  },
  {
    name: "Store",
    children: [
      { name: "Impact Theory Founders Key", link: "https://opensea.io/collection/impact-theory-founders-key" },
      { name: "Merry Modz", link: "https://opensea.io/collection/merry-modz" },
      { name: "Relentless Lifemap", link: "https://opensea.io/collection/relentlesslifemap" },
      { name: "Pre-Genesis", link: "https://tokentrove.com/collection/ProjectKyzenPreGenesis" },
    ],
  },
  {
    name: "Perks",
    children: [
      {
        name: "Perks",
        link: "/perks",
        target: "current"
      },
    ],
  },
  {
    name: "WIKI",
    children: [
      {
        name: "Project Kyzen",
        link: "https://wiki.projectkyzen.io"
      },
      {
        name: "Founders Key",
        link: "https://wiki.founderskey.io/"
      }
    ],
  },
];
