import { Box, Container, Link, Stack, Text } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import BannerTextRenderer from "../cms/BannerTextRenderer";

export const Banner = () => {
  const [bannerContent, setBannerContent] = useState();
  const dataFetchedRef = useRef(false);

  const loadData = () => {
    fetch("/api/get-banner-content")
      .then(async (response) => {
        //sort by display order
        const data = await response.json();
        setBannerContent(data);
      })
      .catch((err) => console.log(`catch case: ${err}`));
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    loadData();
  }, []);

  if(!bannerContent) return null

  return (
    <Box bgColor="#0ff3ff">
      <Box borderBottomWidth="0px" bg="bg.surface">
          <Stack
            direction="row"
            spacing={{ base: "3", md: "4" }}
            justify="space-between"
            align={{ base: "start", md: "center" }}
          >
            <Box textAlign='center' width='100%' fontSize={{ base: "8px", md: "16px" }}>{BannerTextRenderer(bannerContent)}</Box>
          </Stack>
      </Box>
    </Box>
  );
};
